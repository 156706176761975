<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addDiscounts'})">الخصومات</dashboard-page-title>
    <main-table :fields="fields" :list_url="'merchant/discounts/coupons'" paginationName="خصومات"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      fields: [
        { label: 'الإسم', key: 'title', class: 'text-center' },
        { label: 'الكود', key: 'code', class: 'text-center' },
        { label: 'عدد مرات الإستخدام', key: 'max_used', class: 'text-center' },
        { label: 'استهداف', key: 'target', class: 'text-center' },
        { label: 'الحد الأقصى لقيمة الخصم', key: 'min_order_price', class: 'text-center' },
        { label: 'استخدام الكود مع العروض', key: 'can_use_with_promotion', class: 'text-center' },
        { label: 'الحد الأقصى لقيمة الخصم', key: 'max_discount', class: 'text-center' },
        { label: 'عدد مرات استخدام الكود للمستخدم الواحد', key: 'max_user_used', class: 'text-center' },
        { label: 'تاريخ البدء', key: 'start_date', class: 'text-center' },
        { label: 'تاريخ الإنتهاء', key: 'end_date', class: 'text-center' },
        { label: 'تاريخ الإنشاء', key: 'created_at', class: 'text-center' }
      ]
    }
  },
  methods: {
    addMerchant () {
    }
  }
}
</script>
